import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import YouTubeGrid from 'react-youtube-grid'
import { Link } from "react-router-dom";



function VFXPage() {


    const Bannerdata = { title: "VFX", img: "6.jpg" }


    return (
        <div >
            <Banner data={Bannerdata} />
            <div className="m-10">
            < YouTubeGrid  youtubeUrls = { [ "hGbDmlZe-KY", "jq3X5f3WpLw","Ze8U5Aer0z8","C1R2HZThf38","QM-hCCyKfME"] }  / >
            </div>
            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div >
    )
}

export default VFXPage