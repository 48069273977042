import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import { Link } from "react-router-dom";



function FDMPage() {
    const photos = [
        {
            src: "./work/FDM/23116757_874358669396162_3077936561383920261_o.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/FDM/17761170_766798543485509_2292868233549686499_o.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/FDM/17492824_766798486818848_2075163550046377430_o.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/FDM/17492616_766798386818858_2169478041924417447_o.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/FDM/16700648_766798483485515_5879779750190051831_o.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/FDM/13173182_598803010285064_2470308559523555572_o.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/FDM/13173077_598803136951718_6464586602314286398_o.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/FDM/13172993_598802960285069_5355628496491871043_o.jpg",
            width: 4,
            height: 3
        },
     
     
    ];

    const Bannerdata = { title: "3D列印", img: "w-yn3-shih-134069174-3836903553033221-11267013420166797-n.jpg" }


    return (
        <div >
            <Banner data={Bannerdata} />

            <div className="m-10">
                <Gallery photos={photos} />
 
            </div>
            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div>
    )
}

export default FDMPage