import React from 'react'
import { Link } from "react-router-dom";
import Card from "./Card"
import Banner from './Banner'
import { Routes, Route, Navigate } from "react-router-dom"

function Work() {

  const Bannerdata = { title: "WORK", img: "w-yn3-shih-2.jpg" }
  const carddata = [
    { title: "室內設計", img: "w-yn3-shih-4.jpg", link: "/interior" },
    { title: "建築設計", img: "w-yn3-shih-night.jpg", link: "/arch" },
    { title: "VFX 特效合成 ", img: "6.jpg", link: "/vfx" },
    { title: "Unreal Engine 場景設計", img: "HighresScreenshot00012.png", link: "/ue" },
    { title: "產品建模 / 動畫", img: "w-yn3-shih-56866432-308258056516058-1086070699421859840-n.jpg", link: "/product" },
    { title: "3D列印物件建模 / 整理", img: "w-yn3-shih-134069174-3836903553033221-11267013420166797-n.jpg", link: "/3d" },
    { title: "概念美術設計", img: "untitledPS2.png", link: "/concept" },
  ]





  return (
    <div >

      <Banner data = {Bannerdata} />

      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {carddata &&
            carddata.map((d) => {
              return <Card data={d} />;
            })}
        </div>
      </div>



    </div>
  )
}

export default Work