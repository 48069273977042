import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import YouTubeGrid from 'react-youtube-grid'
import { Link } from "react-router-dom";




function ConceptPage() {
    const photos = [
        {
            src: "./work/concept/123.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/untitledPS2.png",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/w-yn3-shih-3houseps.jpg",
            width: 1,
            height: 1
        },
        {
            src: "./work/concept/w-yn3-shih-0133.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/w-yn3-shih-104410036-3258206080902974-9897418838026774-n.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/w-yn3-shih-146189683-3931642843559291-2757768931852374307-n.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/w-yn3-shih-h2final.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/concept/w-yn3-shih-intheends.jpg",
            width: 9,
            height: 16
        },
    ];

    const Bannerdata = { title: "Concept", img: "./work/concept/untitledPS2.png" }


    return (
        <div >
            <Banner data={Bannerdata} />

            <div className="m-10">
                <Gallery photos={photos} />
            </div>
            <div className="m-10">

                <div >
                    < YouTubeGrid youtubeUrls={["uFUHUHov3go", "BAenaT56Mv0", "0D2X0XVov4k"]} />
                </div>
                <div className="grid justify-items-center">
                    <Link to="/work">
                        <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ConceptPage