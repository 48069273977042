import React from 'react'
import { Link } from "react-router-dom";


const Card = ({ data }) => {
    return (
        <Link to={data.link}>
              <div className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={data.img}

                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-2xl text-gray-700">

                      <h2 href="/interior">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {data.title}
                      </h2>
                    </h3>
                    <h2 className="mt-1 text-sm text-gray-500"></h2>
                  </div>

                </div>

              </div>
            </Link>
    );
  };
  

export default Card