import React from 'react'
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="z-40 top-0 sticky bg-white bg-opacity-80 " >
      <header className="text-gray-600 body-font font-mono z-40 ">
        <div className="top-0 sticky container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link to="/" aria-label="logo">
            <img src="Wayne-Shih.png" alt="logo tailus" className="w-40 m-auto " />
          </Link>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <Link to="/" className="mr-5 hover:text-gray-900">Home</Link>

            <Link to="/work" className="mr-5 hover:text-gray-900">Work</Link>

           
            <Link to="/contact">
              <button className="w-auto px-3 py-1   mr-5 text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">Contact ME</button>
            </Link>
          </nav>

        </div>
      </header>
    </div>
  )
}

export default Navbar