import React from "react";
import Gallery from "react-photo-gallery";
import Banner from '../Banner'
import YouTubeGrid from 'react-youtube-grid'
import { Link } from "react-router-dom";



function UEPage() {
    const photos = [
        {
            src: "./work/UE/w-yn3-shih-11.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00000.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00007.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00008.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00009.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00010.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00011.jpg",
            width: 4,
            height: 3
        },{
            src: "./work/UE/w-yn3-shih-highresscreenshot00012.jpg",
            width: 4,
            height: 3
        },

    ];

    const Bannerdata = { title: "UE", img: "HighresScreenshot00012.png" }


    return (
        <div >
            <Banner data={Bannerdata} />

            <div className="m-10">
                <Gallery photos={photos} />
            </div>
            <div className="m-10">

                <div >
                    < YouTubeGrid youtubeUrls={["0D2X0XVov4k", "CwB-ozSqwxY", "H8aq_UIVT-g", "8Bo9fqJOa7A","JkuV7wfMuZk"]} />
                </div>

            </div>
            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div>
    )
}

export default UEPage