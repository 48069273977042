import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";  // 引入燈箱樣式
import Banner from '../Banner'
import YouTubeGrid from 'react-youtube-grid'
import { Link } from "react-router-dom";

function Interiorpage() {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const photos = [
        {
            src: "./work/Interior/HighresScreenshot00004.png",
            width: 16,
            height: 9
        },
        {
            src: "./work/Interior/HighresScreenshot00005.png",
            width: 16,
            height: 9
        },
        {
            src: "./work/Interior/HighresScreenshot00006.png",
            width: 16,
            height: 9
        },
        {
            src: "./work/Interior/HighresScreenshot00007.png",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-4.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-3.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-2.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-5.jpg",
            width: 1,
            height: 1
        },
        {
            src: "./work/Interior/w-yn3-shih-131479792-3816987035024873-1423442427100907745-n.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-132174320-3816656435057933-1700361600602877239-n.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-156657587-4013641885359386-1855679679037119402-n.jpg",
            width: 4,
            height: 3
        },
        {
            src: "./work/Interior/w-yn3-shih-156800770-4011119092278332-1011268072198737640-n.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-166404844-4077229209000653-7000200868526389722-n.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-fin.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-image0001fin.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/Interior/w-yn3-shih-image0001ps.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-toilet-ps2.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-untitled1.jpg",
            width: 16,
            height: 9
        },
        {
            src: "./work/Interior/w-yn3-shih-untitled13gi.jpg",
            width: 1,
            height: 1
        },
        {
            src: "./work/Interior/w-yn3-shih-untitled14.jpg",
            width: 3,
            height: 4
        },
        {
            src: "./work/Interior/w-yn3-shih-untitledps.jpg",
            width: 4,
            height: 3
        },

    ];

    const Bannerdata = { title: "Interior", img: "w-yn3-shih-4.jpg" }

    // 點擊圖片後打開燈箱
    const openLightbox = (event, { index }) => {
        setCurrentImageIndex(index);
        setLightboxOpen(true);
    };

    // 燈箱內處理左右瀏覽圖片
    const nextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % photos.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((currentImageIndex + photos.length - 1) % photos.length);
    };

    return (
        <div>
            <Banner data={Bannerdata} />
            <div className="m-10">
                < YouTubeGrid youtubeUrls={["4qvLsmGE1Uw", "CwB-ozSqwxY", "H8aq_UIVT-g", "8Bo9fqJOa7A", "JkuV7wfMuZk", "T19jJLSEPGo"]} />
            </div>

            <div className="m-10">
                {/* 圖片畫廊，添加 onClick 事件來打開燈箱 */}
                <Gallery photos={photos} onClick={openLightbox} />
            </div>



            {/* 如果燈箱打開則顯示燈箱 */}
            {lightboxOpen && (
                <div style={{ zIndex: 9999, position: 'relative' }}>
                    <Lightbox
                        mainSrc={photos[currentImageIndex].src}
                        nextSrc={photos[(currentImageIndex + 1) % photos.length].src}
                        prevSrc={photos[(currentImageIndex + photos.length - 1) % photos.length].src}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={prevImage}
                        onMoveNextRequest={nextImage}
                    />
                </div>
            )}


            <div className="grid justify-items-center">
                <Link to="/work">
                    <button className=" w-auto px-3 py-5 m-10  text-sm  text-white uppercase transition-colors duration-200 transform bg-gray-500 rounded-md lg:w-auto hover:bg-gray-300 hover:text-gray-500 focus:outline-none  focus:bg-gray-300">回上一頁</button>
                </Link>
            </div>
        </div>
    );
}

export default Interiorpage;